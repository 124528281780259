import { useEffect, useState, FormEvent } from 'react';
import { useDisclosure } from '@mantine/hooks';
import { useForm, isNotEmpty, UseFormReturnType } from '@mantine/form';
import { Stack, Button, Grid, Group, LoadingOverlay } from '@mantine/core';
import { useNavigate, useParams } from 'react-router-dom';
import { Account, CommonHash } from '@types';
import Emitter from './Emitter';
import Receiver from './Receiver';
import Lines from './Lines';
import Totals from './Totals';
import Transport from './Transport';
import Charges from './Charges';
import SectionButton from './SectionButton';
import Customs from './Customs';
import { DOCUMENTTYPEOPTIONS } from '@constants';
import { NoFoliosModal } from '@components/NoFoliosModal/NoFoliosModal';
import { mapShortDTENameToDTENumberType } from '@utils';
import {
  exportType,
  buildInitialValues,
  receiptType,
  noteType,
  checkPurchaseInvoiceReference,
} from './formUtils';
import {
  referenceDateValidation,
  issueDateValidator,
  kindOfSaleValidator,
  serviceIndicatorValidator,
  paymentTypeValidator,
  taxIdValidator,
  companyValidator,
  emailsValidator,
  referenceDescriptionValidator,
  referenceCodeValidator,
  referenceOriginalNumberValidator,
  exportTypeObligatoryValidator,
  dispatchTypeObligatoryValidator,
  transportRouteValidator,
  companyAdditionalInfoValidator,
} from './formValidators';
import General from './General';
import { useAddDocument, useDocument, useNewDocument, useUpdateDocument } from '@api/documents';

import References from './References';
import { useCafs } from '@api/cafs';
import Previsualization from '../previsualization/drawer';
import { useUserActiveAccount } from '@api/users';
import { useNavbar } from '@providers/navbar';
// import { IconArrowBarToLeft } from '@tabler/icons-react';
// import styles from '../previsualization/Previsualization.module.scss';

function Form() {
  const { id } = useParams();
  let document: any;
  let isLoading: boolean;
  ({ data: document, isLoading } = useNewDocument(id));
  ({ data: document, isLoading } = useDocument(id));
  const { data: account, isLoading: isAccountLoading } = useUserActiveAccount();
  const { mutate: AddDocument, isSuccess: isAddSuccess } = useAddDocument();
  const [showReceiver, setShowReceiver] = useState(document?.company?.tax_id != '66666666-6');
  const { mutate: UpdateDocument, isSuccess: isUpdateSuccess } = useUpdateDocument();
  const navigate = useNavigate();
  const { data: cafs, isLoading: isCafsLoading } = useCafs();
  const [disableBtns, setDisableBtns] = useState(false);
  const [openedNoFoliosModal, { open: openNoFoliosModal, close: closeNoFoliosModal }] =
    useDisclosure(false);
  const [openedPrevisualization, { open: openPrevisualization, close: closePrevisualization }] =
    useDisclosure(false);
  const { toggleNavbar } = useNavbar();
  const [documentType, setDocumentType] = useState(document?.data?.type || 'ElectronicInvoice');

  const form: UseFormReturnType<any> = useForm({
    initialValues: buildInitialValues(documentType, document?.data, account?.data as Account),
    validateInputOnBlur: true,
    validate: {
      emitter: {
        account_activity: isNotEmpty('Este campo es requerido'),
        account_address: isNotEmpty('Este campo es requerido'),
      },
      document: {
        issue_date: (value) => issueDateValidator(value),
        payment_type: (value, values) => paymentTypeValidator(value, values),
        kind_of_sale: (value, values) => kindOfSaleValidator(value, values),
        service_indicator: (value, values) => serviceIndicatorValidator(value, values),
        currency: (value, values) => exportTypeObligatoryValidator(value, values.document.type),
        contact_email: (value) => emailsValidator(value, false),
        company_additional_info: (value, values) =>
          companyAdditionalInfoValidator(value, values.document.type, values),
        transfer_index: (value, values) =>
          dispatchTypeObligatoryValidator(value, values.document.type),
        lines_attributes: {
          name: isNotEmpty('Este campo es requerido'),
          quantity: isNotEmpty('Este campo es requerido'),
          amount: (value, values) =>
            values.document.type !== 'ElectronicSettlement'
              ? isNotEmpty('Este campo es requerido')(value)
              : null,
        },
        references_attributes: {
          original_dte_type: isNotEmpty('Este campo es requerido'),
          original_number: (value, values, path) =>
            referenceOriginalNumberValidator(value, values, path),
          date: (value, values) => referenceDateValidation(value, values),
          code: (value, values) => referenceCodeValidator(value, values),
          description: (value, values, path) => referenceDescriptionValidator(value, values, path),
        },
        transport_attributes: {
          exportation_sales_mode: (value, values) =>
            exportTypeObligatoryValidator(value, values.document.type),
          receiver_country_id: (value, values) =>
            exportTypeObligatoryValidator(value, values.document.type),
          transport_route: (value, values) => transportRouteValidator(value, values),
        },
      },
      company: {
        tax_id: (value, values) => taxIdValidator(values.document.type, value),
        legal_name: (value, values) => companyValidator(values.document.type, value, values),
        activity: (value, values) => companyValidator(values.document.type, value, values),
        address: (value, values) => companyValidator(values.document.type, value),
        commune: (value, values) => companyValidator(values.document.type, value),
      },
    },
    transformValues: (values) => ({
      ...values,
      document: {
        ...values.document,
        dispatch_type: parseInt(values.document.dispatch_type) || null,
        transfer_index: parseInt(values.document.transfer_index) || null,
        transfer_code: parseInt(values.document.transfer_code) || null,
        lines_attributes: values.document.lines_attributes.map((line: CommonHash) => ({
          ...line,
          total: values.document.type === 'ElectronicSettlement' ? line.total_amount : null,
          discount: (line.discount || 0) / 100,
          reference_kind: parseInt(line.reference_kind) || null,
          billing_indicator: parseInt(line.billing_indicator) || 0,
        })),
        global_discounts_attributes: values.document.global_discounts_attributes.map(
          (globalDiscount: CommonHash) => ({
            ...globalDiscount,
            value: (globalDiscount.value || 0) / 100,
          }),
        ),
        charges_attributes: values.document.charges_attributes.map((charge: CommonHash) => ({
          ...charge,
          net_amount: charge.net_amount || 0,
          exempt_amount: charge.exempt_amount || 0,
        })),
      },
      company: {
        ...values.company,
        commune: !exportType(values.document.type) ? values.company.commune : null,
      },
    }),
  });

  useEffect(() => {
    form.values.document.references_attributes.forEach((_: CommonHash, index: number) => {
      form.validateField(`document.references_attributes.${index}.date`);
    });
  }, [form.values.document.issue_date]);

  useEffect(() => {
    if (
      form.values.document.withholding_tax_percentage == 0 &&
      checkPurchaseInvoiceReference(form.values.document.references_attributes || [])
    )
      form.setFieldValue('document.withholding_tax_percentage', 0.19);
  }, [form.values.document.references_attributes]);

  useEffect(() => {
    form.setValues(buildInitialValues(documentType, document?.data, account?.data));
  }, [document, account]);

  useEffect(() => {
    form.reset();
    form.setValues(buildInitialValues(documentType, document?.data, account?.data));
  }, [documentType]);
  useEffect(() => {
    if (!isLoading) {
      const currentDteType = mapShortDTENameToDTENumberType(form.values.document.type);
      const currentCaf = cafs?.data.find((caf) => caf.dte_type === currentDteType);
      if (currentCaf?.left_to_use === 0 || (isCafsLoading === false && currentCaf === undefined)) {
        setDisableBtns(true);
        openNoFoliosModal();
      } else {
        setDisableBtns(false);
      }
    }
  }, [form.values.document.type, isCafsLoading, isLoading]);

  useEffect(() => {
    if (
      noteType(form.values.document.type) &&
      form.values.document.references_attributes.length == 0
    ) {
      form.insertListItem('document.references_attributes', {
        original_number: '',
        original_dte_type: '',
        date: '',
        global_index: false,
        code: '',
        description: '',
        _destroy: false,
      });
      form.setFieldValue('document.withholding_tax_percentage', 0);
    } else if (form.values.document.type === 'ElectronicPurchaseInvoice') {
      form.setFieldValue('document.withholding_tax_percentage', 0.19);
    } else {
      form.setFieldValue('document.withholding_tax_percentage', 0);
    }
  }, [form.values.document.type]);

  useEffect(() => {
    setShowReceiver(!receiptType(documentType) || form.values.company.tax_id !== '66666666-6');
    if (exportType(form.values.document.type)) {
      form.setFieldValue('company.tax_id', '55555555-5');
      form.setFieldValue('document.transport_attributes.visible', true);
    } else if (form.values.document.type === 'ElectronicDispatchGuide') {
      form.setFieldValue('document.transport_attributes.visible', true);
    } else if (
      form.values.company.tax_id == '' &&
      (form.values.document.type === 'ElectronicReceipt' ||
        form.values.document.type === 'ElectronicExemptReceipt')
    ) {
      setShowReceiver(false);
      form.setFieldValue('company.tax_id', '66666666-6');
    }
  }, [form.values.document.type]);

  useEffect(() => {
    if (isAddSuccess || isUpdateSuccess) {
      if (id && document?.data.file_import_id) {
        navigate(`/carga-masiva/${document?.data.file_import.uuid}`);
      } else {
        navigate('/emitidos');
      }
    }
  }, [isAddSuccess, isUpdateSuccess]);

  const noFoliosModalDTEName = () => {
    return (
      DOCUMENTTYPEOPTIONS.find((option) => option.value === form.values.document.type)?.label || ''
    );
  };

  const handleOpenPrevisualization = () => {
    toggleNavbar();
    openPrevisualization();
  };

  const readyToShow = isLoading === false && isAccountLoading === false && isCafsLoading === false;
  return (
    <Grid>
      <Grid.Col span={openedPrevisualization ? 6 : 12}>
        <form
          onSubmit={form.onSubmit(
            (values: CommonHash, event: FormEvent<HTMLFormElement> | undefined) => {
              event?.preventDefault();
              event?.stopPropagation();
              if (document?.data?.uuid) {
                UpdateDocument({ uuid: document?.data.uuid, data: values });
              } else {
                AddDocument({ data: values });
              }
            },
            (validationErrors, values, event) => {
              console.log(validationErrors, values, event);
            },
          )}
        >
          <Stack gap={20} pb={50} pos="relative">
            <LoadingOverlay
              visible={!readyToShow}
              zIndex={1000}
              overlayProps={{ radius: 'sm', blur: 2 }}
            />
            <Emitter form={form} account={account?.data} readyToShow={readyToShow} />
            <General form={form} setDocumentType={setDocumentType} />
            {form.values.document.references_attributes.length > 0 &&
              noteType(form.values.document.type) && <References form={form} />}
            {showReceiver && <Receiver form={form} />}
            {form.values.document.transport_attributes.visible && <Transport form={form} />}
            {exportType(form.values.document.type) && <Customs form={form} />}
            <Lines form={form} />
            {form.values.document.charges_attributes.length > 0 && <Charges form={form} />}
            {form.values.document.references_attributes.length > 0 &&
              !noteType(form.values.document.type) && <References form={form} />}
            <Grid>
              <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                <SectionButton form={form} setShowReceiver={setShowReceiver} />
              </Grid.Col>
              <Grid.Col
                span={{ base: 12, md: 6, lg: receiptType(form.values.document.type) ? 12 : 6 }}
              >
                <Totals form={form} />
              </Grid.Col>
            </Grid>
            <Group align="center" justify="center">
              {!openedPrevisualization && (
                <Button w={200} onClick={handleOpenPrevisualization}>
                  Previsualizar
                </Button>
              )}
              <Button
                w={200}
                className={disableBtns ? '' : 'btn-outline-primary'}
                type="submit"
                disabled={disableBtns}
                onClick={() => form.setFieldValue('document.status', 'draft')}
              >
                Guardar
              </Button>
              <Button
                type="submit"
                w={200}
                disabled={disableBtns}
                onClick={() => form.setFieldValue('document.status', 'sent')}
              >
                Emitir
              </Button>
            </Group>
          </Stack>
          <NoFoliosModal
            opened={openedNoFoliosModal}
            close={closeNoFoliosModal}
            dteType={noFoliosModalDTEName()}
          />
        </form>
      </Grid.Col>
      {/* <Button
        className={
          openedPrevisualization ? styles.toClosePrevisualization : styles.toOpenPrevisualization
        }
        onClick={handleOpenPrevisualization}
      >
        <IconArrowBarToLeft />
      </Button> */}
      {openedPrevisualization && (
        <Grid.Col span={6}>
          <Previsualization
            form={form}
            account={account?.data}
            close={closePrevisualization}
            company={form.values.company}
          />
        </Grid.Col>
      )}
    </Grid>
  );
}

export default Form;
