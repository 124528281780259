import {
  Grid,
  Text,
  TextInput,
  Paper,
  Select,
  TagsInput,
  Tooltip,
  rem,
  Group,
  Autocomplete,
} from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';
import { UseFormReturnType } from '@mantine/form';
import { Commune, CommonHash } from '@types';
import { format } from 'rut.js';
import { useCommunes } from '@api/communes';
import { useCompanies } from '@api/companies';
import { useEffect } from 'react';
import { exportType, receiptType, noteType, purchaseType } from './formUtils';
import { useCountries } from '@api/countries';

function Receiver({ form }: { form: UseFormReturnType<any> }) {
  const { data: receiversOptions } = useCompanies();
  const { data: countries } = useCountries();
  const transformedCountries = countries?.data.map((country) => {
    return {
      label: country.name,
      value: String(country.id),
    };
  });
  const taxIdOptions = receiversOptions?.data.map((r: CommonHash) => r.tax_id);
  const { data: communes } = useCommunes();
  const transformedCommunes = communes?.data.map((commune: Commune) => {
    return {
      label: commune.name,
      value: commune.name,
    };
  });
  const onBlurTax = (value: string) => {
    if (value === '') return;
    form.setFieldValue('company.tax_id', format(value, { dots: false }));
  };
  const contactEmailTooltip = (
    <Tooltip label="Se puede agregar un máximo de 3 emails. Apretar enter para agregarlo">
      <IconInfoCircle
        style={{ width: rem(16), height: rem(16), color: 'var(--mantine-color-grey-4)' }}
      />
    </Tooltip>
  );

  const directionAsterisk = () => {
    if (receiptType(form.values.document.type)) {
      if (
        form.values.document.service_indicator === '1' ||
        form.values.document.service_indicator === '2'
      ) {
        return true;
      } else {
        return false;
      }
    } else if (noteType(form.values.document.type)) {
      return false;
    } else {
      return true;
    }
  };
  useEffect(() => {
    if (taxIdOptions?.includes(form.values.company.tax_id)) {
      const receiver = receiversOptions?.data.find(
        (c: CommonHash) => c.tax_id === form.values.company.tax_id,
      ) as CommonHash;
      form.setFieldValue('company.legal_name', receiver.legal_name);
      form.setFieldValue('company.activity', receiver.activity);
      form.setFieldValue('company.address', receiver.address);
      form.setFieldValue('company.commune', receiver.commune);
    }
  }, [form.values.company.tax_id]);

  return (
    <Paper shadow="xl" p="lg">
      <Text fw={700} mb={10} fz={20}>
        Receptor
      </Text>
      <Grid>
        {!exportType(form.values.document.type) && (
          <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
            <Autocomplete
              withAsterisk
              label="RUT"
              placeholder="RUT"
              {...form.getInputProps('company.tax_id')}
              onBlur={(e) => onBlurTax(e.target.value)}
              data={taxIdOptions}
            />
          </Grid.Col>
        )}
        {exportType(form.values.document.type) ||
        (purchaseType(form.values.document.type) && form.values.company.tax_id === '55555555-5') ? (
          <Grid.Col span={{ base: 12, md: 7 }}>
            <TextInput
              withAsterisk
              label="Nombre"
              placeholder="Nombre"
              maxLength={100}
              {...form.getInputProps('document.company_additional_info')}
            />
          </Grid.Col>
        ) : (
          <>
            <Grid.Col span={{ base: 12, md: 6, lg: 5 }}>
              <TextInput
                withAsterisk
                label="Razón Social"
                placeholder="Razón Social"
                maxLength={100}
                {...form.getInputProps('company.legal_name')}
              />
            </Grid.Col>
            {!receiptType(form.values.document.type) && (
              <Grid.Col span={{ base: 12, md: 6, lg: 5 }}>
                <TextInput
                  withAsterisk={!exportType(form.values.document.type)}
                  label="Giro"
                  placeholder="Giro"
                  maxLength={40}
                  {...form.getInputProps('company.activity')}
                />
              </Grid.Col>
            )}
          </>
        )}
        <Grid.Col span={{ base: 12, md: 6, lg: 5 }}>
          <TextInput
            withAsterisk={directionAsterisk()}
            label="Dirección"
            placeholder="Dirección"
            maxLength={70}
            {...form.getInputProps('company.address')}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
          {exportType(form.values.document.type) ? (
            <Select
              withAsterisk={!exportType(form.values.document.type)}
              label="Nacionalidad"
              placeholder="Nacionalidad"
              data={transformedCountries}
              {...form.getInputProps('document.nationality')}
              value={form.values.document.nationality}
              onChange={(value) => form.setFieldValue('document.nationality', value)}
              searchable
            />
          ) : (
            <Group style={{ position: 'relative' }}>
              <Select
                withAsterisk={directionAsterisk()}
                label="Comuna"
                placeholder="Comuna"
                data={transformedCommunes}
                {...form.getInputProps('company.commune')}
                value={form.values.company.commune}
                onChange={(value) => form.setFieldValue('company.commune', value)}
                searchable
                styles={{
                  root: {
                    width: '100%',
                  },
                }}
              />
              <Tooltip label="Seleccionar Extranjero si la comuna/ciudad es de otro país">
                <IconInfoCircle
                  style={{
                    width: rem(16),
                    height: rem(16),
                    color: 'var(--mantine-color-gray-6)',
                    position: 'absolute',
                    top: '5px',
                    left: '70px',
                  }}
                />
              </Tooltip>
            </Group>
          )}
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
          <TagsInput
            label="Email de contacto"
            placeholder="Email"
            // maxLength={80}
            maxTags={3}
            rightSection={contactEmailTooltip}
            clearable
            {...form.getInputProps('document.contact_email')}
            value={form.values.document.contact_email}
            splitChars={[',', ' ', '|', ';']}
          />
        </Grid.Col>
      </Grid>
    </Paper>
  );
}

export default Receiver;
