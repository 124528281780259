import { CommonHash } from '@types';
import { validate } from 'rut.js';
import { receiptType, noteType, exportType, purchaseType } from './formUtils';

function validReferenceDate(refDateStr: string, docDateStr: string) {
  const refDate = new Date(refDateStr);
  const docDate = new Date(docDateStr);
  const today = new Date();
  return refDate <= docDate && refDate <= today;
}

export function referenceDateValidation(value: string, values: CommonHash) {
  if (!noteType(values.document.type)) return null;
  if (value !== '' && validReferenceDate(value, values.document.issue_date)) return null;
  if (value === '') return 'Este campo es requerido';
  return 'La fecha no puede ser posterior a la fecha del documento';
}

export function emailsValidator(value: string[], required: boolean) {
  if (value === undefined) return;
  const check = value.map((email: string) => emailValidator(email, required));
  if (check.includes('Este campo es requerido')) return 'Este campo es requerido';
  if (check.includes('Email inválido')) return 'Email inválido';
  return;
}
export function emailValidator(value: string, required: boolean) {
  if (value === '' && !required) return;
  if (required) return 'Este campo es requerido';
  const regex = /^[\w.-]+@[a-zA-Z\d.-]+.[a-zA-Z]{2,}$/;
  if (value === '' || regex.test(value)) return null;
  return 'Email inválido';
}

export function paymentTypeValidator(value: string, values: CommonHash) {
  if (
    receiptType(values.document.type) ||
    noteType(values.document.type) ||
    exportType(values.document.type) ||
    values.document.type === 'ElectronicDispatchGuide'
  )
    return null;
  if (!receiptType(values.document.type) && value != '') return null;
  return 'Este campo es requerido';
}
export function kindOfSaleValidator(value: string, values: CommonHash) {
  if (
    receiptType(values.document.type) ||
    noteType(values.document.type) ||
    exportType(values.document.type) ||
    values.document.type === 'ElectronicDispatchGuide'
  )
    return null;
  if (!receiptType(values.document.type) && value != '') return null;
  return 'Este campo es requerido';
}
export function serviceIndicatorValidator(value: string, values: CommonHash) {
  if (!receiptType(values.document.type)) return null;
  if (receiptType(values.document.type) && value != '') return null;
  return 'Este campo es requerido';
}

export function taxIdValidator(dteType: string, value: string) {
  if (receiptType(dteType)) return null;
  if (!receiptType(dteType) && value != '' && validate(value)) return null;
  if (value == '') return 'Este campo es requerido';
  return 'El rut del receptor es inválido';
}

export function exportTypeObligatoryValidator(value: string, dteType: string) {
  if (!exportType(dteType)) return null;
  if (value !== '') return null;
  return 'Este campo es requerido';
}

export function companyAdditionalInfoValidator(
  value: string,
  dteType: string,
  values?: CommonHash,
) {
  if (!exportType(dteType) && !purchaseType(dteType)) return null;
  if (purchaseType(dteType) && values?.company?.tax_id != '55555555-5') return null;
  if (value !== '') return null;
  return 'Este campo es requerido';
}
export function issueDateValidator(value: string) {
  if (value === '') return 'Este campo es requerido';
  return null;
}

export function companyValidator(dteType: string, value: string, values?: CommonHash) {
  if (purchaseType(dteType) && values?.company?.tax_id === '55555555-5') {
    return null;
  }

  if (receiptType(dteType) || exportType(dteType)) return null;
  if (!receiptType(dteType) && value != '') return null;
  if (value == '') return 'Este campo es requerido';
  return 'El rut del receptor es inválido';
}

export function referenceDescriptionValidator(value: string, values: CommonHash, path: string) {
  const index = path.split('.')[2];
  if (!values.document.references_attributes[index].global_index) return null;
  if (value !== '') return null;
  return 'Este campo es requerido';
}

export function referenceCodeValidator(value: string, values: CommonHash) {
  if (!noteType(values.document.type)) return null;
  if (value !== '') return null;
  return 'Este campo es requerido';
}

export function referenceOriginalNumberValidator(value: string, values: CommonHash, path: string) {
  const index = path.split('.')[2];
  if (values.document.references_attributes[index].global_index) return null;
  if (value !== null) return null;
  return 'Este campo es requerido';
}

export function transportRouteValidator(value: string, values: CommonHash) {
  if (!exportType(values.document.type)) return null;
  if (values.document.service_indicator !== '' && value === '')
    return 'Este campo es requerido debido al indicador de servicio seleccionado';
  return null;
}

export function dispatchTypeObligatoryValidator(value: string, dteType: string) {
  if (dteType !== 'ElectronicDispatchGuide') return null;
  if (value !== '') return null;
  return 'Este campo es requerido';
}
