import localforage from 'localforage';
import { ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react';
const STORED_SESSION_KEY = 'filters';
export interface Filters {
  tax_id: string[];
  legal_name: string[];
  document_type: string[];
  status: string[];
  start_date: Date | null;
  end_date: Date | null;
  code: string | undefined;
  number: string | undefined;
  external_id: string;
}
interface FiltersContext {
  filters: Filters;
  setFilters: (s: Partial<Filters> | null) => void;
  filtersCounter: number;
}

export const defaultState: Filters = {
  tax_id: [],
  legal_name: [],
  document_type: [],
  status: [],
  start_date: null,
  end_date: null,
  code: '',
  number: '',
  external_id: '',
};

export const FiltersContext = createContext<FiltersContext>({
  filters: defaultState,
  setFilters: () => { },
  filtersCounter: 0,
});

export const useFilters = () => useContext(FiltersContext);

const FiltersProvider = ({ children }: { children: ReactNode }) => {
  const [filters, setFilters_] = useState<Filters>(defaultState);
  const [filtersCounter, setFiltersCounter] = useState(0);
  useEffect(() => {
    localforage
      .getItem(STORED_SESSION_KEY)
      .then((stored: unknown) => {
        if (!stored) stored = defaultState;
        setFilters_(stored as Filters);
        setFiltersCounter(counterFunction(stored as Filters));
      })
      .catch(() => {
        setFilters_(defaultState);
        setFiltersCounter(0);
      });
  }, []);

  useEffect(() => {
    localforage.setItem(STORED_SESSION_KEY, filters || defaultState);
  }, [filters]);

  const contextValue = useMemo(() => {
    const setFilters = (newFilters: Partial<Filters> | null) => {
      if (newFilters) {
        const newFilterObject = { ...filters, ...newFilters };
        setFilters_(newFilterObject);
        setFiltersCounter(counterFunction(newFilterObject));
      } else {
        setFilters_(defaultState);
        setFiltersCounter(0);
      }
    };

    return { filters, setFilters, filtersCounter };
  }, [filters]);

  return <FiltersContext.Provider value={contextValue}>{children}</FiltersContext.Provider>;
};

export default FiltersProvider;

const counterFunction = (filters: Filters) => {
  let counter = 0;
  Object.values(filters).forEach((value) => {
    if (Array.isArray(value)) {
      counter += value.length;
    } else if (value) {
      counter += 1;
    }
  });
  return counter;
};
